import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../Images/CommonImg";
import { Image } from "../../Images/Image";
import SITE_PATHS from "../Path";
import { toast, ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { newUserVisiting } from "../../ApiComponent/Apifunction";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { validationEmail } from "../../ApiComponent/Validation";
export default function ThankYou() {
    const [state, setState] = useState({ Name: "", Email: "", Phone: "", VisaType: "", Country: "" })
    const submit = async () => {
        const { Name, Email, Phone, VisaType, Country } = state
        if (Name === "") {
            toast("Please Enter correct info for Name");
        }
        else if (Email === "") {
            toast("Please Enter correct info for Email");
        }
        else if (Phone === "") {
            toast("Please Enter correct info for Phone");
        }
        else if (Phone != "" && Phone.length < 10) {
            toast("Please Enter correct info for Phone");
        }
        else if (VisaType === "") {
            toast("Please Select Visa Type");
        }
        else if (Country === "") {
            toast("Please Select Country");
        }
        else {

            const val = await validationEmail(Email)
            if (!val) { toast("Please Enter correct info for Email"); }
            else {
                const FromUser = "info@aussieimmigration.com.au"
                const data = await newUserVisiting(Name, Email, Phone, VisaType, Country, FromUser)
                toast("Details Saved Succesfully");
                setState({ Name: "", Email: "", Phone: "", VisaType: "", Country: "" })
            }
        }
    }

    return (
        <>
            <div className="page-title-area">
                <ToastContainer position="top-center" />
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Thank You</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Thank You For Being A Valuable Customer </li>
                        </ul>
                    </div>
                </div>
                <div className="shape-img3">

                    <img className="bannermargin_img" src={CommonImg[3]?.path}></img>
                </div>

            </div>
            <div className="contact-info-area pt-100 bannermargin pb-70">
                <div className="container">
                    <div className="section-title"><span className="sub-title">
                        <img src={CommonImg[0]?.path} alt="contact"/>
                        Our Address </span>
                        <p>Experience something completely different contact us</p>

                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-map-marker-alt"></i></div>
                                <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                <h3>Australia</h3>
                                <p>1/16 Mcdougall, ST Milton, 4064, QLD, Australia</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-phone-alt"></i></div>
                                <div className="icon"><i className="fas fa-phone-alt"></i></div>
                                <h3>Contact</h3>
                                <p>Mobile: <a href="tel:0731529575">(+61) 7315-29575</a></p>
                                <p><a href="mailto:info@aussieimmigration.com.au">info@aussieimmigration.com.au</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-clock"></i></div>
                                <div className="icon"><i className="fas fa-clock"></i></div>
                                <h3>Hours of Operation</h3>
                                <p>Monday - Friday: 09:00 - 20:00</p>
                                <p>Sunday &amp; Saturday: 10:30 - 22:00</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-map-marker-alt"></i></div>
                                <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                <h3>India</h3>
                                <p>SCO-58, Emperor Square, TDI City, Sonipat Haryana, India</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-phone-alt"></i></div>
                                <div className="icon"><i className="fas fa-phone-alt"></i></div>
                                <h3>Contact</h3>
                                <p>Mobile: <a href="tel:+918295195516">(+91) 82951-95516</a></p>
                                <p> <a href="mailto:info@aussieimmigration.com.au">info@aussieimmigration.com.au</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-clock"></i></div>
                                <div className="icon"><i className="fas fa-clock"></i></div>
                                <h3>Hours of Operation</h3>
                                <p>Monday - Friday: 09:00 - 20:00</p>
                                <p>Sunday &amp; Saturday: 10:30 - 22:00</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-map-marker-alt"></i></div>
                                <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                <h3>Fatehabad - (HR)</h3>
                                <p>Near Kumar Kitab Ghar, Thakur Basti, Near Bus Stand, Fatehabad</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-phone-alt"></i></div>
                                <div className="icon"><i className="fas fa-phone-alt"></i></div>
                                <h3>Contact</h3>
                                <p>Mobile: <a href="tel:+918168638283">(+91) - 81686 - 38283</a></p>
                                <p> <a href="mailto:info@aussieimmigration.com.au">info@aussieimmigration.com.au</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-clock"></i></div>
                                <div className="icon"><i className="fas fa-clock"></i></div>
                                <h3>Hours of Operation</h3>
                                <p>Monday - Friday: 09:00 - 20:00</p>
                                <p>Sunday &amp; Saturday: 10:30 - 22:00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="contact-area pb-100">
                <div className="container">
                    <div className="section-title"><span className="sub-title">
                        <img src={CommonImg[0]?.path} alt="contact"/>
                        Get in Touch</span>
                        <h2>Thanks for being awesome! </h2>
                        <p>Feel free to contact for the further clarification.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-image"><img
                                src={CommonImg[18]?.path}
                                alt="contact" /></div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="contact_form">
                              <p>
                              We have received your information and would like to thank you for connecting to us. 
                              If your inquiry is urgent, please use the telephone number listed above to talk to one of our staff members. 
                              </p>
                              <p>
                              Our team will be contacting you momentarily.
                              </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}