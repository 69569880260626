import React from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "../component/page/AboutUs"
import ContactUs from "../component/page/ContactUs";
import Blog1 from "../component/page/blogs/Blog1";
import Blog2 from "../component/page/blogs/Blog2";
import Blog3 from "../component/page/blogs/Blog3";
import Course1 from "../component/page/course/Course1";
import Course2 from "../component/page/course/Course2";
import Course3 from "../component/page/course/Course3";
import Course4 from "../component/page/course/Course4";
import Course5 from "../component/page/course/Course5";
import Course6 from "../component/page/course/Course6";
import Course7 from "../component/page/course/Course7";
import Course8 from "../component/page/course/Course8";
import Courses from "../component/page/Courses";
import CoursesWithDetails from "../component/page/CoursesWithDetails";
import Dashboard from "../component/page/Dashboard";
import Disclaimer from "../component/page/Disclaimer";
import FAQ from "../component/page/FAQ";
import Gallary from "../component/page/Gallary";
import History from "../component/page/History";
import IELTS from "../component/page/IELTS";
import OurTeam from "../component/page/OurTeam";
import PrivacyPolicy from "../component/page/PrivacyPolicy";
import PTE from "../component/page/PTE";
import Services from "../component/page/Services";
import ServicesWithDetails from "../component/page/ServicesWithDetails";
import SpokenEnglish from "../component/page/SpokenEnglish";
import Study_Visa_In_Australia from "../component/page/Study_Visa_In_Australia";
import Study_Visa_In_Canada from "../component/page/Study_Visa_In_Canada";
import Study_Visa_In_NewZealand from "../component/page/Study_Visa_In_NewZealand";
import Study_Visa_In_UK from "../component/page/Study_Visa_In_UK";
import Study_Visa_In_USA from "../component/page/Study_Visa_In_USA";
import TermsOfService from "../component/page/TermsOfService";
import Testimonials from "../component/page/Testimonials";
import  LandingPage from "../component/LandingPage";
import Userlist from "../component/admin/Userlist";
import Login from "../component/admin/Login";
import ThankYou from "../component/page/ThankYou";

export default function RouterComponent() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Thank-You" element={<ThankYou />} />
      </Routes>
    </>
  );
}
